:root {
  --color-black: #313131;
  --color-white: #fff;
  --color-red: tomato;
  --color-blue: #3498db;
  --light-bg: #fafafa;
  --dark-bg: #1a1c34;
  --font-family-monospace: 'Ubuntu Mono', monospace;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1200px;
  --transition-func: all .3s ease-in-out;
}

.light-theme {
  --site-color: var(--color-black);
  --site-bg: var(--light-bg);
}

.dark-theme {
  --site-color: var(--color-white);
  --site-bg: var(--dark-bg);
}

@font-face {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu Mono'), local('UbuntuMono-Regular'),
  url('./fonts/ubuntu-mono-v9-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/ubuntu-mono-v9-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Mono Bold'), local('UbuntuMono-Bold'),
  url('./fonts/ubuntu-mono-v9-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./fonts/ubuntu-mono-v9-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 14px;
  font-family: sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 768px) {
  html {
      font-size: 15px;
  }
}

@media (min-width: 992px) {
  html {
      font-size: 16px;
  }
}

body {
  font-family: var(--font-family-monospace);
  font-size: 1rem;
  line-height: 1.35;
  color: var(--site-color);
  background-color: var(--site-bg);
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

svg {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6, p {
  margin-bottom: .5rem;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;
  border-bottom: 1px solid rgba(229, 229, 229, 0.6);
}

.logo,
.header__actions {
  flex: 0 0 50px;
  max-width: 50px;
}

.eye-left,
.brow-left,
.brow-right {
  transition: var(--transition-func);
}

.logo:hover .brow-left {
  transform: translateY(-12px);
}

.logo:hover .brow-right {
  transform: translateY(12px);
}

.logo--mobile {
  display: block;
  width: 120px;
  margin: 48px auto 12px;
}

.logo--mobile svg {
  max-width: 120px;
}

.nav ul {
  list-style: none;
}

.nav li {
  padding: 0 10px;
  text-transform: uppercase;
}

.nav a {
  letter-spacing: 1px;
  opacity: .55;
  transition: var(--transition-func);
}

.nav a:hover,
.nav a:focus,
.nav a.active {
  opacity: 1;
}

.nav--mobile {
  font-size: 2rem;
}

.nav--mobile li {
  margin-bottom: 1rem;
}

.nav--desktop {
  flex-grow: 1;
  font-size: 1.15rem;
}

.nav--desktop ul {
  display: flex;
  justify-content: center;
  margin: 0 -10px;
}

.logo--desktop,
.nav--desktop {
  display: none;
}

@media (min-width: 768px) {
  .logo--mobile,
  .nav--mobile {
      display: none;
  }

  .logo--desktop,
  .nav--desktop {
      display: block;
  }
}

.theme-changer {
  position: relative;
  width: 50px;
  height: 24px;
  border-radius: 24px;
  background-color: #0f1114;
  transition: var(--transition-func);
  cursor: pointer;
}

.theme-changer__dot {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 0 2px #ccc;
  animation: moveDotToLeft .4s forwards;
}

.theme-changer__light,
.theme-changer__dark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.theme-changer__light {
  right: 5px;
}

.theme-changer__dark {
  left: 5px;
}

@keyframes moveDotToLeft {
  from {left: 25px;}
  to {left: 0;}
}

@keyframes moveDotToRight {
  from {left: 0;}
  to {left: 25px;}
}

.theme-changer.is-checked .theme-changer__dot {
  animation: moveDotToRight .4s forwards;
}

.main {
  min-height: calc(100vh - 100px);
  padding: 1.3rem 0;
}

.footer {
  padding: .5rem 0;
  border-top: 1px solid rgba(226, 226, 226, 0.6);
}

.footer__copyright {
  text-align: center;
  font-size: .85rem;
}

.content p {
  font-size: 1.15rem;
}

.content a {
  color: var(--color-blue);
}

.contact-me {

}

.contact-me__block {

}

.contact-me__description {
  font-size: 1.3rem;
}

.contact-me__line {
  display: flex;
  align-items: center;
  padding: .15rem 0;
  font-size: 1.15rem;
}

.contact-me__subtitle {
  flex: 0 0 90px;
  max-width: 90px;
}

.contact-me__value a {
  color: var(--color-blue);
}

.menu-btn {
  position: relative;
  z-index: 15;
  width: 32px;
  height: 32px;
  padding: 2px;
  border: 0;
  background-color: transparent;
}

.menu-btn span {
  display: block;
  width: 28px;
  height: 2px;
  margin: 0 auto;
  background-color: var(--site-color);
  transition: var(--transition-func);
}

.menu-btn span:nth-child(2) {
  margin: 5px 0;
}

.menu-btn.is-open span:nth-child(2) {
  margin: 3px 0;
}

@media (min-width: 768px) {
  .menu-btn {
      display: none;
  }
}

.mobile-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--site-bg);
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition-func);
}

.mobile-menu.is-open {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
